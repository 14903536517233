import React from "react";
import * as styles from "./post.module.scss";
import { Link } from "gatsby";

const Post = (props) => {
  let post = props.post;

  return (
    <React.Fragment>
      <div className={styles.posts}>
        <div className={styles.post}>
          <div className={styles.header}>
            <h1 className={styles.title}>
              <Link to={post.url}>{post.title}</Link>
            </h1>
            <p className={styles.date}>{post.frontmatter.date}</p>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: post.html,
            }}
          ></div>
          <ul className={styles.metas}>
            <li>分类:<span>
              <Link to={"/categories/" + post.frontmatter.categories}>
                {post.frontmatter.categories}
              </Link></span></li>
            <li>
              标签:
              {post.tags.map((tag, index) => {
                return <span key={index}><Link to={"/tags/" + tag}>{tag}</Link></span>;
              })}
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Post;
